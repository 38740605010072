import moment from "moment";
import { Link } from "react-router-dom";
import Terms from "../modals/terms";
import { useState } from "react";
import { Button } from "react-bootstrap";

const Footer = ({ logged = null, isTerms = false }) => {
  const [modalShow, setModalShow] = useState(false);
  const year = moment().year();
  if (logged) {
    return (
      <div className="footer2 mt-5">
        © {year} MIABCONLINE from <strong className="ms-1">Diazapps</strong>.
      </div>
    );
  } else {
    return (
      <>
        <div className="footer justify-content-between px-3">
          <div>
            © {year} MIABCONLINE from <strong className="ms-1">Diazapps</strong>
            .
          </div>
          {!isTerms ? (
            <div>
              <Link to="/privacy">
                <a className="text-link-1 ms-2 cursor-pointer">
                  Política de Privacidad
                </a>
              </Link>
            </div>
          ) : (
            <div>
              <Link to="/">
                <a className="text-link-1 ms-2 cursor-pointer">Regresar</a>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
};

export default Footer;
