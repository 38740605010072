import { useState, useEffect } from "react";
import { database } from "../config/firebase";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import { UserAuth } from "../context/AuthContext";

const useFetchSent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const userRef = ref(database, "users/" + user.uid + "/accessCode");
        const usersRef = ref(database, "users/");
        let arrWords = [];
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          const res = query(
            ref(database, "users"),
            orderByChild("accessCode"),
            equalTo(data)
          );
          onValue(res, (snapshot) => {
            if (snapshot.exists()) {
              let dataItem;
              snapshot.forEach((item) => {
                if (item.val().username) {
                  dataItem = item.val();
                  dataItem.id = item.key;
                  onValue(usersRef, (snapshotUsers) => {
                    if (snapshotUsers.exists()) {
                      snapshotUsers.forEach((snapUser) => {
                        if (
                          snapUser.val().receivedWords &&
                          snapUser.val().receivedWords.length > 0
                        ) {
                          const received = snapUser.val().receivedWords;
                          const wordsFilter = received.filter((rec) => {
                            if (rec.userid === dataItem.id) {
                              rec.sentTo = snapUser.val().email;
                              return rec;
                            }
                          });
                          arrWords.concat(wordsFilter);
                          setData((prevState) => [
                            ...prevState,
                            ...wordsFilter,
                          ]);
                        }
                      });
                      setLoading(false);
                    }
                  });
                }
              });
            }
          });
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchResource();
  }, []);

  return { data, loading, error };
};

export default useFetchSent;
