import React, { useState, useEffect } from "react";
import Header from "../../components/protected/Header";
import ReactPaginate from "react-paginate";
import Footer from "../../components/footer";
import Words from "../../components/protected/words/Words";
import { database } from "../../config/firebase";
import { onValue, ref } from "firebase/database";

const WordsReceived = ({ values }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [arrSender, setArrSender] = useState(values.data.receivedWords);
  const [itemsPerPage] = useState(6);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  let sliced = [];
  if (values.data.receivedWords) {
    sliced = values.data.receivedWords.slice(indexOfFirstItem, indexOfLastItem);
  }

  useEffect(() => {
    if (values.data.receivedWords) {
      sliced.map((item) => {
        const refUser = ref(database, "users/" + item.userid);
        onValue(refUser, (snapshot) => {
          const data = snapshot.val();
          item.sender = data.email;
          setArrSender([...arrSender], item);
        });
      });
    }
  }, []);

  /* switch (values) {
        case value:
            
            break;
    
        default:
            break;
    } */

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">{values.title}</h1>
            </div>
            <h4>Palabras</h4>
            {sliced.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-sm text-center">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Español</th>
                      <th scope="col">Sílabas</th>
                      <th scope="col">Inglés</th>
                      <th scope="col">Tema</th>
                      <th scope="col">Enviada por</th>
                      <th scope="col">Detalles</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrSender && (
                      <Words
                        currentItems={arrSender}
                        itemOffset={indexOfFirstItem}
                        handleImage={values.handleImage}
                        isModule={values.isModule}
                      />
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    onPageChange={paginate}
                    pageCount={Math.ceil(sliced.length / itemsPerPage)}
                    previousLabel={"Anterior"}
                    nextLabel={"Siguiente"}
                    containerClassName={"pagination"}
                    className={"pagination "}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link previous"}
                    nextLinkClassName={"page-link next"}
                    activeLinkClassName={"active"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                  />
                </div>
              </div>
            ) : (
              <h5 className="text-center">No hay palabras recibidas.</h5>
            )}
          </div>
        </div>
      </div>
      <Footer logged={values.logged} />
    </>
  );
};

export default WordsReceived;
