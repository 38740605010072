import Loading from '../../components/Loading';
import Swal from 'sweetalert2';
import 'animate.css';
import WordsSent from './WordsSent';
import useFetchSent from '../../hooks/useFetchSent';

const WordsCreatedContainer = ({isEdited=null, isModule=null}) => {

    const { data, loading, error} = useFetchSent();
    
    const handleImage = (img, name, sound) => {

        Swal.fire({
            title: name,
            html: '<audio src="'+sound+'" controls type="audio/mp4"/>',
            imageUrl: img,
            imageAlt: 'Image',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
            hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
            }
        })

    }

    let title = "Lista de palabras enviadas";
   
    console.log(data);
    const values = {
        data: data,
        handleImage,
        isEdited,
        isModule,
        logged:true,
        title
    }

    if (loading)
        return <Loading loggin={true} isModule={isModule}/>

    return <WordsSent values={values}/>
}

export default WordsCreatedContainer