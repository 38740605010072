import { RouterProvider } from "react-router-dom";
import React from "react";
import { createRoot } from "react-dom/client";
import { router } from "./pages";
import { AuthContextProvider } from "./context/AuthContext";

// Importing the Bootstrap 5 CSS
import "bootstrap/dist/css/bootstrap.min.css";

createRoot(document.getElementById("root")).render(
  <AuthContextProvider>
    <RouterProvider router={router} />
  </AuthContextProvider>
);
