import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  MEASUREMENT_ID,
} from "./config.js";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_API_KEY : FIREBASE_API_KEY),
  authDomain: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN : FIREBASE_AUTH_DOMAIN),
  databaseURL: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DATABASE_URL: FIREBASE_DATABASE_URL),
  projectId: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID),
  storageBucket: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET: FIREBASE_STORAGE_BUCKET),
  messagingSenderId: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID),
  appId: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_APP_ID: FIREBASE_APP_ID) ,
  measurementId: (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_MEASUREMENT_ID: MEASUREMENT_ID)
}
 /* console.log('key', firebaseConfig.apiKey);
 console.log('domain', '1' + process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, '2' + FIREBASE_AUTH_DOMAIN); */

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

/* const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' }); */
/* 
export default firebase; */
