import { useController } from 'react-hook-form';

const Input = ({
	id,
	control,
	name,
	label = '',
	type = 'text',
	placeholder = '',
	required = false,
	inputId = 'input-div'
}) => {
	const { field } = useController({ control, name });

	const onFocus = () => {
		const elem = document.getElementById(inputId);
		if (!elem) {
			return;
		}

		elem.classList.add('focus');
	};

	const onBlur = () => {
		const elem = document.getElementById(inputId);
		if (!elem) {
			return;
		}
		if (field.value === '') {
			elem.classList.remove('focus');
		}
	};

	return (
		<div className={`div ${required ? 'required' : ''}`}>
			<label htmlFor={id} className="pb-2">{label}</label>
			<input
				id={id}
				type={type}
				onFocus={onFocus}
				onChange={field.onChange}
				onBlur={(e) => {
					field.onBlur(e);
					onBlur();
				}}
				className="input"
				value={field.value}
				placeholder={placeholder}
			/>
		</div>
	);
};
export default Input;
