import { useLocation } from "react-router-dom";
import charly from "../assets/images/charly.svg";
import charly2 from "../assets/images/charly2.svg";
import wave from "../assets/images/wave.svg";

const Body = ({ children }) => {
  const { pathname } = useLocation();
  const img = pathname === "/" ? charly2 : charly;

  return (
    <>
      <img className="wave" src={wave} alt="" />
      <div className="container">
        <div className="img">
          <img src={img} alt=" " />
        </div>
        {children}
      </div>
    </>
  );
};

export default Body;
