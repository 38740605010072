import React from 'react'
import { Link } from 'react-router-dom';

const Words = ({currentItems, handleImage, itemOffset, isModule}) => {
    if (currentItems.length <= 0) {
        return  <tr className="text-center">
                   <td colSpan={6}>No hay palabras.</td>
                </tr>
    }
    return currentItems.map((word,index) => {
            return <tr key={index}>
                <td>{index+1+itemOffset}</td>
                <td>{word.spanishName}</td>
                <td>{word.spanishBreakUp}</td>
                <td>{word.englishName}</td>
                {isModule!=="family" && (<td>{word.tema}</td>)}
                {isModule==="received" && (<td>{word.sender}</td>)}
                {isModule==="sent" && (<td>{word.sentTo}</td>)}
                <td>
                    <Link onClick={() => handleImage(word.imagePath, word.spanishName, word.spanishSound)} className="text-center btn-4 btn-sm btn-outline-secondary">Ver</Link>   
                </td>
            </tr>
    });
}

export default Words