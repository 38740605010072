import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '../../components/form/input';
import { useState } from 'react';

const Step3 = ({ defaultValues, next, step }) => {
	const { handleSubmit, watch, control } = useForm({
		defaultValues: {
			...defaultValues,
			/* code: undefined */
		}
	});
	const [requiredCode, setRequiredCode] = useState('');
	const [requiredEmail, setRequiredEmail] = useState('');
	const [requiredPassword, setRequiredPassword] = useState('');

	const onSubmit = (data) => {
		if(!data.code){
			/* alert("El avatar es obligatorio"); */
			setRequiredCode("El código de acceso es requerido");
			return;
		}
		if(!data.email){
			/* alert("El avatar es obligatorio"); */
			setRequiredEmail("El correo electrónico es requerido");
			return;
		}
		if(!data.password){
			/* alert("El avatar es obligatorio"); */
			setRequiredPassword("La contraseña es requerida");
			return;
		}
		next(data, step);
	};

	return (
		<div className="login-content">
			<form onSubmit={handleSubmit(onSubmit)}>
				<img src="/assets/img/logo-02.svg" alt="" />
				<h3 className="title">¡Crea una cuenta!</h3>
				<div id="input-div-code" className="input-div one mb-0">
					<div className="i">
						<i className="fas fa-key"></i>
					</div>
					<Input
						control={control}
						name="code"
						type="text"
						id="code"
						required
						inputId="input-div-code"
						label="Código de acceso"
					/>
				</div>
				<div className='text-danger mb-2'>{requiredCode}</div>
				<div id="input-div-email" className="input-div one mb-0">
					<div className="i">
						<i className="fas fa-user"></i>
					</div>
					<Input
						control={control}
						name="email"
						type="email"
						id="email"
						required
						inputId="input-div-email"
						label="Nombre / Correo"
					/>
				</div>
				<div className='text-danger mb-2'>{requiredEmail}</div>
				<div id="input-div-password2" className="input-div pass mb-0">
					<div className="i">
						<i className="fas fa-lock"></i>
					</div>
					<Input
						control={control}
						name="password"
						type="password"
						id="password"
						inputId="input-div-password2"
						required
						label="Contraseña"
					/>
				</div>
				<div className='text-danger mb-2'>{requiredPassword}</div>
				<input type="submit" className="btn" value="Siguiente" />
				<Link to="/register/step2" className="btn-2">
					atras
				</Link>
				<Link to="/">
					Ya tienes una cuenta? <strong>Inicia Sesión.</strong>
				</Link>
			</form>
		</div>
	);
};

export default Step3;
