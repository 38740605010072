import React from "react";
import Accordion from "react-bootstrap/Accordion";
import {
  AiFillDashboard,
  AiOutlineDownload,
  AiOutlineThunderbolt,
} from "react-icons/ai";
import { BiSpreadsheet } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { UserAuth } from "../../context/AuthContext";
import UserGuide from "../../../src/manual.pdf";

import { Link } from "react-router-dom";

const Menu = ({ show, toggle }) => {
  const { user } = UserAuth();
  return (
    <>
      {(toggle || show === "") && (
        <nav
          id="sidebarMenu"
          className={"col-md-3 col-lg-2 d-md-block sidebar collapse " + show}
        >
          <div className="position-sticky pt-3">
            <ul className="nav flex-column border-top">
              <li className="nav-item mt-3">
                <Link
                  className="nav-link text-link-2 active"
                  aria-current="page"
                  to="/dashboard"
                >
                  <span data-feather="home">
                    <AiFillDashboard className="pt-0 me-1" />
                  </span>
                  Dashboard
                </Link>
              </li>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="text-link-2">
                    <BiSpreadsheet className="pt-0 me-1" />
                    Palabras
                  </Accordion.Header>
                  <Accordion.Body>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-link-2 active"
                        aria-current="page"
                        to="/words/created"
                      >
                        <span data-feather="home"></span>
                        Agregadas
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-link-2"
                        aria-current="page"
                        to="/words/received"
                      >
                        <span data-feather="home"></span>
                        Recibidas
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-link-2"
                        aria-current="page"
                        to="/words/sent"
                      >
                        <span data-feather="home"></span>
                        Enviadas
                      </Link>
                    </li>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <IoIosPeople className="pt-0 me-1" />
                    Familia
                  </Accordion.Header>
                  <Accordion.Body>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-link-2 active"
                        aria-current="page"
                        to="/family/created"
                      >
                        <span data-feather="home"></span>
                        Miembros Agregados
                      </Link>
                    </li>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="text-link-2">
                    <AiOutlineThunderbolt className="pt-0 me-1" />
                    Acciones
                  </Accordion.Header>
                  <Accordion.Body>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-link-2 active"
                        aria-current="page"
                        to="/actions/create-text"
                      >
                        <span data-feather="home"></span>
                        Crear texto
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-link-2 active"
                        aria-current="page"
                        to="/actions/list-texts"
                      >
                        <span data-feather="home"></span>
                        Lista de textos
                      </Link>
                    </li>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ul>
            <div className="border-top bottom-div nav flex-column">
              <p>Sesión iniciada como:</p>
              <p className="text-break">{user.email}</p>
              <Link
                className="btn-3 btn-sm btn-outline-secondary px-1 pt-2 mr-2"
                to={UserGuide}
                download="Manual-de-Usuario-MIABC"
                target="_blank"
                rel="noreferrer"
              >
                Manual de MIABC
                <AiOutlineDownload size={22} className="ps-1" />
              </Link>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Menu;
