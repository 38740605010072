import moment from "moment";
import "moment/locale/es";

export const lastTime = (data) => {
  /* console.log(data); */
  moment.locale("es");
  /* let date=''; */
  if (data.log) {
    const numConn = data.log.length;
    const pos = numConn - 1;
    /* console.log(pos); */

    const now = moment(); // create a moment with the current time
    const then = moment.unix(data.log[pos].timeStamp); // create a moment with the other time timestamp in seconds
    const diff = now.diff(then, "hours"); // get the millisecond difference
    const date = moment.unix(data.log[pos].timeStamp).fromNow();
    return { date, diff, numConn };
  }

  return null;
};
