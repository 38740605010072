import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from '../config/firebase';
import {  equalTo, ref, set, update, query, orderByChild, onValue, serverTimestamp, child, get } from 'firebase/database';
import { ref as sRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, database } from '../config/firebase';
import Swal from 'sweetalert2';
import { error1, error2, error3, error4, error5, error6, error7 } from '../constants/codeValidationErrors';
/* import Loading from '../components/Loading'; */


const UserContext = createContext();
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isWeb, setIsWeb] = useState(false);
  const [thereIsLearner, setThereIsLearner] = useState(false);
  /* useEffect(() => {
    validateCode();
  }, [error]); */
  const validateCode = (code) => {
    if (code===error1.code) {
      Toast.fire({
        icon: 'error',
        title: error1.message,
      }).then(() => {
        return;
      });
    }
    if (code===error2.code) {
        Toast.fire({
          icon: 'error',
          title: error2.message,
        }).then(() => {
          return;
        });
    }
  }

  const createUser = async (data) => {
      setLoading(true);
      /* console.log(data); */
      if (!data.avatar_file) {
        Toast.fire({
          icon: 'error',
          title: 'Debe subir una imagen de perfil, regrese al paso 2',
          
        })
        return;
      }
      if (!data.role) {
        Toast.fire({
          icon: 'error',
          title: 'Debe seleccionar un rol, regrese al paso 1',
          
        })
        return;
      }
      
      
      searchCode(data);
      /* validateCode(); */
       /* console.log(error); */
       /* if(error===false) {
        const codeRef = query( ref(database, 'accesscodes'), orderByChild('code'), equalTo(data.code) );
        console.log("code access", codeRef);
          onValue(codeRef, (snapshot) => {
            let itemKey
            snapshot.forEach(item => {
              itemKey = item.key
            })
        });
        
      } */
  };
  const create = (data, itemKey) => {
    /* console.log("Sin errores", data);
    console.log("Data Item", itemKey); */
    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    createUserEmail(data, itemKey);  
  }

  const createUserEmail = (data, itemKey) => {
      createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        const storageRef = sRef(storage, `/covers-web/${data.avatar_file.name}`);
       /*  console.log("ItemKey", itemKey); */
        const uploadTask = uploadBytesResumable(storageRef, data.avatar_file);
       /*  console.log(storageRef);  */
        uploadTask.on( "state_changed",(snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            /* console.log('Upload is ' + percent + '% done'); */
            switch (snapshot.state) {
              case 'paused':
                /* console.log('Upload is paused'); */
                break;
              case 'running':
                /* console.log('Upload is running'); */
                break;
            }
          
            // update progress
            setPercent(percent);
        },
        (err) => {/* console.log(err); */ setLoading(false);},
          () => {
            // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  /* console.log("Download url: ", url); */
                  const user = userCredential.user;
                  let uid = user.uid;
                  // console.log("data" , data);
                  set(ref(database, 'users/' + uid), {
                    accessCode: data.code,
                    email: data.email,
                    profileImageLink : url,
                    role : data.role
                  });
                  updateCode(itemKey);
                  /* .then(() => {
                    updateCode(itemKey);
                  })
                  .catch((error) => {
                    console.log(error);
                  }); */
                });
              }
            );
      /* const ref = database.ref('accesscodes').orderByChild("code").equalTo(data.code); */
      /* const ref = ref(database, 'accesscodes').orderByChild("code").equalTo(data.code); */
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        /* console.log(errorCode); */
        if (errorCode === error3.code) {
          Toast.fire({
            icon: 'error',
            title: error3.message,
          }).then(() => {
            return;
          });
        }else{
          Toast.fire({
            icon: 'error',
            title: "Ocurrió un error inesperado",
          }).then(() => {
            return;
          });
        }
      });
    
    /* await createUserWithEmailAndPassword(auth, data.email, data.password); */
  }

  const updateCode = (itemKey) => {

      update(ref(database, 'accesscodes/' + itemKey), {
      value_web : true
      }).then(() => {
        /* console.log("Saved"); */
        setLoading(false);
        window.location.replace('/account');
      })
      .catch((error) => {
        setLoading(false);
        /* console.log(error); */
      }); 
  }

  const searchCode = (data) => {
    /* const error01 = 1;
    const error02 = 2; */
    const codeRef = query( ref(database, 'accesscodes'), orderByChild('code'), equalTo(data.code) );
    /* console.log("code access", codeRef); */
      onValue(codeRef, (snapshot) => {
        /* console.log(snapshot); */
			  let itemKey;
			  let dataItem;
        snapshot.forEach(item => {
          itemKey = item.key;
          dataItem = item.val();
        })
        if (itemKey!==undefined && itemKey) {
            /* console.log("Por válido itemKey entró, itemkey", itemKey);    */
            /* console.log("Antes de entrar al error 2", dataItem.value); */
            if (dataItem.value_web===true) {
              setLoading(false);
              /* console.log("llegó error 2", dataItem.value_web); */
              validateCode(error2.code);
              /* console.log("Entra por codigo usado, value : " + dataItem.value_web); */
             /*  setError(error2.code); */
              /* return error2; */
            }
            if (!dataItem.value_web) {
              create(data, itemKey);
            }
         
          /* return itemKey; */
        }else{
          setLoading(false);
          validateCode(error1.code);
          /* setError(error1.code); */
          /* console.log("Por undefined itemKey entró"); */
        }
        
        /* console.log("llegó", error01); */
        /* return error1; */   
      },{
        onlyOnce: true
      });
    
  }
  /* const signIn = async (email, password) => {
    //try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
    //} catch (err) {
    //  console.error(err);
    //  setLoading(false);
    //}
  }; */

  const validateThereIs = async (userId) => {
    const userRef = ref(database, 'users/' + userId + '/accessCode');
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          const res = query(ref(database, 'users' ), orderByChild('accessCode'), equalTo(data));
          onValue(res, (snapshot) => {
              if (snapshot.exists()) {
                  let found = false;
                  snapshot.forEach(item => {
                    if (item.val().username) {
                      found = true;
                      setThereIsLearner(true);
                    }
                  })
                  if (!found) {
                    logout();
                  }
              }
          });
        });
  }

  const validateWebUser = async (userId) => {
    const dbRef = ref(database);
    get(child(dbRef, 'users/'+ userId)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (!data.username) {
          setIsWeb(true); 
        }else{
          logout();
        }
      }
    });
  }

  const saveLog = (userId) => {
      /* console.log(userId); */
      const dbRef = ref(database);
      get(child(dbRef, 'users/'+ userId +'/logs')).then((snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          const data = snapshot.val();
         /*  console.log(data); */
          arr = data;          
          arr.push({
            userId: userId,
            timestamp: serverTimestamp()
          })
        } else {
          arr.push({
            userId: userId,
            timestamp: serverTimestamp()
          })
          
        }
         set(ref(database, 'users/'+ userId +'/logs'), arr);
      });

      
  }

  /* const userInfo = (userId) => {
  
    const userRes = ref(database, 'users/' + userId );
    onValue(userRes, (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      setUser({...user, info:data});
    }); 
  } */

  const passwordRecovery = async (email) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth,email);
      setLoading(false);
    } catch (err) {
      /* console.error(err); */
      switch (err.code) {
        case error5.code:
          setError(error5.message);
        case error6.code:
          setError(error6.message);
        case error4.code:
          setError(error4.message);
        case error7.code:
          setError(error7.message);
        default:
          setError("Chequee su conexión");
      }
      setLoading(false);
    }
    

  }

  const signIn = async (email, password) =>  {
    const userCredentials = await signInWithEmailAndPassword(auth, email, password);
    /* console.log(userCredentials); */
    const user = userCredentials.user;
    await validateWebUser(user.uid);  
    await validateThereIs(user.uid);
  }

  const logout = async () => {
      try {
        setLoading(true);
        await signOut(auth);
        if(thereIsLearner && isWeb){
          setIsWeb(false);
          setThereIsLearner(false);
        }
        setLoading(false);
      } catch (err) {
        /* console.error(err); */
        setLoading(false);
      }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      /* console.log(currentUser); */
      setUser(currentUser);
      /* setLoading(false); */
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if(isWeb){
      saveLog(user.uid);
    }
  
  }, [isWeb])
  

  /* if(loading)
    return <Loading/> */
  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn, passwordRecovery, percent, loading, error, thereIsLearner, isWeb }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
