import Loading from '../../components/Loading';
import WordsCreated from './WordsCreated';
import useFetch from '../../hooks/useFetch';
import Swal from 'sweetalert2';
import 'animate.css';
import FamilyMember from './FamilyMember';
import WordsReceived from './WordsReceived';

const WordsCreatedContainer = ({isEdited=null, isModule=null}) => {

    const { data, loading, error } = useFetch();
    
    const handleImage = (img, name, sound) => {

        Swal.fire({
            title: name,
            html: '<audio src="'+sound+'" controls type="audio/mp4"/>',
            imageUrl: img,
            imageAlt: 'Image',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
            hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
            }
        })

    }

    let title = "Lista de palabras creadas";

    switch (isModule) {
        case "family":
            title = "Lista de miembros agregados";
            break;
        case "received":
            title = "Lista de palabras recibidas";     
            break;     
        default:
           break;
    }
   
    /* console.log(data); */
    const values = {
        data: data,
        handleImage,
        isEdited,
        isModule,
        logged:true,
        title
    }

    if (loading)
        return <Loading loggin={true}/>
    if(isModule==="family")
        return <FamilyMember values={values}/>
    if(isModule==="received")
        return <WordsReceived values={values}/>

    return <WordsCreated values={values}/>
}

export default WordsCreatedContainer