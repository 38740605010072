import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

const defaultAvatar = '/assets/img/noprofil.png';

const Step2 = ({ defaultValues, next, step }) => {
	const { handleSubmit, watch, setValue } = useForm({
		defaultValues
	});

	const [required, setRequired] = useState(null);
	const avatar = watch('avatar');

	const onSubmit = (data) => {
		if(!data.avatar){
			/* alert("El avatar es obligatorio"); */
			setRequired("La imagen de perfil es requerida");
			return;
		}
		next(data, step);
		
	};
	
	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.addEventListener('load', (event) => {
			setValue('avatar', event.target.result);
		});
		reader.readAsDataURL(file);
		setValue('avatar', e.target.result);
		setValue('avatar_file', file);
	};

	return (
		<div className="login-content">
			<form onSubmit={handleSubmit(onSubmit)}>
				<img src="/assets/img/logo-02.svg" alt="" />
				<h3 className="title">¡Crea una cuenta!</h3>
				<label className="upload" htmlFor="camera">
					<img
						className="user_avt"
						src={avatar ? avatar : defaultAvatar}
						width="100"
						height="100"
						alt=""
					/>
					
					<div className="round">
						<input
							id="camera"
							type="file"
							accept="image/*"
							hidden
							onChange={handleFileUpload}
						/>
						<i
							className="fa fa-camera"
							style={{
								color: '#fff'
							}}
						/>
					</div>
				</label>
				<div className='text-danger'>{required ? required : ''}</div>
				<input type="submit" className="btn" value="Siguiente" />
				<Link to="/register/step1" type="submit" className="btn-2">
					atras
				</Link>
				<Link to="/">
					Ya tienes una cuenta? <strong>Inicia Sesión.</strong>
				</Link>
			</form>
		</div>
	);
};

export default Step2;
