import { useState, useEffect } from "react";
import { database } from "../config/firebase";
import {
  ref,
  get,
  set,
  update,
  remove,
  child,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { UserAuth } from "../context/AuthContext";

const useFetchText = (id) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchResource = () => {
      try {
        const textRef = ref(database, `users/${user.uid}/texts/${id}`);
        onValue(textRef, (snapshot) => {
          const data = snapshot.val();
          setData(data);
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchResource();
  }, []);

  return { data, loading, error };
};

export default useFetchText;
