import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../components/assets/images/logo-02.svg";
import { UserAuth } from "../context/AuthContext";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { error4 } from "../constants/codeValidationErrors";
import Loading from "../components/Loading";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleEye, setToggleEye] = useState(false);
  const navigate = useNavigate();
  const { signIn, user, thereIsLearner, isWeb } = UserAuth();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  useEffect(() => {
    if (user) {
      return navigate("/dashboard");
    }
  }, [user]);

  useEffect(() => {
    messageVal();
  }, [thereIsLearner, isWeb, user, signIn]);

  const handleToggleEye = () => {
    setToggleEye(!toggleEye);
  };

  const messageVal = () => {
    if (!thereIsLearner && isWeb) {
      Toast.fire({
        icon: "error",
        title:
          "El aprendíz no ha sido registrado en la app móvil aún, debe completar su registro para acceder a esta plataforma.",
      }).then(() => {
        window.location.reload();
      });
    }
    if (!isWeb && thereIsLearner) {
      Toast.fire({
        icon: "error",
        title: "Este usuario no tiene acceso a esta plataforma.",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      await signIn(email, password);
      setLoading(false);
      /* console.log(thereIsLearner, isWeb); */
      /* if (thereIsLearner && isWeb) {
			
			navigate('/dashboard');
		} */
      messageVal();
      navigate("/dashboard");
    } catch (e) {
      setLoading(false);
      setError(e.code);
    }
  };
  useEffect(() => {
    if (error) {
      let val;
      switch (error) {
        case error4.code:
          val = error4.message;
          break;

        default:
          val = "Verifique sus datos	";
          break;
      }
      Toast.fire({
        icon: "error",
        title: val,
      }).then(() => {
        return;
      });
    }
  }, [error]);

  if (user === undefined) {
    return <Loading />;
  }

  if (loading) return <Loading />;

  return (
    <>
      {/* <div>
			{data.map((item) => (
				
				console.log(item.username)
				
			))}
		</div> */}
      <div className="login-content">
        <form onSubmit={handleSubmit}>
          <img src={logo} alt=" " />
          <h2 className="title">¡Bienvenido!</h2>
          <div id="input-div-login" className="input-div one">
            <div className="i">
              <i className="fas fa-user"></i>
            </div>
            {/* <Input
						control={control}
						name="email"
						type="email"
						id="email"
						inputId="input-div-login"
						label="Correo electrónico"
						onChange={(e) => setEmail(e.target.value)} 
					/> */}

            <Form.Control
              type="email"
              placeholder="Correo electrónico"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div id="input-div-password" className="input-div pass">
            <div className="i">
              <i className="fas fa-lock"></i>
            </div>
            {/* <Input
						label="Contraseña"
						control={control}
						name="password"
						type="password"
						inputId="input-div-password"
						id="password"
						onChange={(e) => setPassword(e.target.value)} 
					/> */}
            <div className="relative">
              <Form.Control
                type={toggleEye === false ? "password" : "text"}
                placeholder="Contraseña"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                className="input-div-password"
              />
              <div
                onClick={handleToggleEye}
                className="i absolute eye-position"
              >
                {toggleEye === false ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </div>
            </div>
          </div>
          <Link to="/forgot-password" className="text-link-1">
            Olvidaste tu contraseña?
          </Link>
          <input type="submit" className="btn" value="Entrar" />
          <Link to="/register/step1" className="text-link-1">
            No tienes una cuenta? <strong>Registrate.</strong>
          </Link>
        </form>
      </div>
    </>
  );
};

export default LoginPage;
