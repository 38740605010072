import { useState } from "react";
import CreateText from "./CreateText";
import { database } from "../../config/firebase";
import {
  update as updateFirebase,
  ref,
  set,
  push as pushToDatabase,
  serverTimestamp as serverTimestampFunction,
} from "firebase/database";
import { UserAuth } from "../../context/AuthContext";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import { redirect, useNavigate, useParams } from "react-router-dom";
import useFetchText from "../../hooks/useFetchText";
import { useEffect } from "react";

const CreateTextContainer = ({ isEdit = false }) => {
  const { user } = UserAuth();
  const [form, setForm] = useState({
    text: "",
    number: 0,
    type: "contextBased",
    topic: "",
    questionType: "-1",
    difficulty: "-1",
    format: "-1",
    levels: "",
    language: "",
  });
  const levelsTexts = [
    "crear",
    "evaluar",
    "analizar",
    "aplicar",
    "entender",
    "recordar",
  ];

  const [loading, setLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { data: dataUser, loading: isLoading, error: errorUser } = useFetch();
  const [qaPairs, setQAPairs] = useState([]);
  const [error, setError] = useState(null);
  const max = 10;
  const maxBlooms = 5;
  const [checked, setChecked] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [checkedLevels, setCheckedLevels] = useState([]);
  const [isCheckedLevels, setIsCheckedLevels] = useState(
    new Array(6).fill(false)
  );

  const navigate = useNavigate();
  let { id } = useParams();
  const {
    data: dataText,
    loading: loadingText,
    error: errorText,
  } = useFetchText(id);

  useEffect(() => {
    handleReset();
  }, [dataText, id]);
  useEffect(() => {
    setCheckedLevels([]);
    setIsCheckedLevels(new Array(6).fill(false));
    if (dataText && dataText.levels && id) {
      // Inicializa los estados según los datos guardados
      const levelsBlank = [];
      const IsCheckedLevels = [...isCheckedLevels];
      console.log(dataText);
      if (
        typeof dataText.levels === "object" &&
        !Array.isArray(dataText.levels)
      ) {
        dataText.levels = Object.values(dataText.levels);
      }
      dataText.levels.forEach((valor) => {
        const indice = levelsTexts.indexOf(valor);
        if (indice !== -1) {
          levelsBlank[indice] = valor;
          IsCheckedLevels[indice] = true;
        }
      });

      setCheckedLevels(levelsBlank);
      setIsCheckedLevels(IsCheckedLevels);
    }
  }, [dataText, id]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleDelete = () => {
    setQAPairs(
      qaPairs.filter((item2) => !checked.some((item1) => item1 === item2.id))
    );
    setForm({
      ...form,
      number: qaPairs.filter(
        (item2) => !checked.some((item1) => item1 === item2.id)
      ).length,
    });
    setChecked([]);
    setIsChecked(new Array(qaPairs.length).fill(false));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setForm({ ...form, [e.target.name]: value });
    setQAPairs([]);
    if (value == "format") {
      handleReset();
    }
  };
  const handleCheck = (event, index) => {
    let updatedList = [...checked];
    let updatedCheckboxes = [...isChecked];

    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
      updatedCheckboxes[index] = true;
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
      updatedCheckboxes[index] = false;
    }
    setChecked(updatedList);
    setIsChecked(updatedCheckboxes);
  };
  const handleCheckLevels = (event, index) => {
    let updatedList = [...checkedLevels];
    let updatedCheckboxes = [...isCheckedLevels];

    if (event.target.checked) {
      updatedList = [...checkedLevels, event.target.value];
      updatedCheckboxes[index] = true;
    } else {
      updatedList.splice(checkedLevels.indexOf(event.target.value), 1);
      updatedCheckboxes[index] = false;
    }
    setCheckedLevels(updatedList);
    setIsCheckedLevels(updatedCheckboxes);
  };
  const handleReset = () => {
    setForm({
      text: id ? dataText.text : "",
      number: id ? dataText.numberOfQuestions : 0,
      type: id ? dataText.type : "",
      questionType: id ? dataText.questionType : "-1",
      difficulty: id ? dataText.difficulty : "-1",
      format: id ? dataText.format : "-1",
      language: id ? dataText.language : "",
    });
    setQAPairs(id ? dataText.questions : []);
  };

  const update = async () => {
    try {
      setLoading(true);
      const textRef = ref(database, `users/${user.uid}/texts/${id}`);
      await updateFirebase(textRef, {
        numberOfQuestions: parseInt(form.number, 10),
        text: form.text,
        questions: qaPairs,
        difficulty: form.difficulty,
        questionType: form.format === "open" ? form.questionType : "MCQ",
        format: form.format,
        levels: checkedLevels,
        language: form.language,
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Ocurrió un error inesperado.",
      }).then(() => {
        navigate("/actions/create-text");
      });
    } finally {
      handleReset();
      setLoading(false);
      Toast.fire({
        icon: "success",
        title: "Actualizado correctamente.",
      }).then(() => {
        navigate("/actions/list-texts");
      });
    }
  };

  const save = async () => {
    try {
      setLoading(true);

      const textRef = pushToDatabase(ref(database, `users/${user.uid}/texts`));

      const newTextKey = textRef.key;

      const newText = {
        id: newTextKey,
        userId: user.uid,
        userAppId: dataUser.id,
        numberOfQuestions: parseInt(form.number, 10),
        text: form.text,
        questions: qaPairs,
        questionType: form.format === "open" ? form.questionType : "MCQ",
        difficulty: form.difficulty,
        format: form.format,
        levels: checkedLevels,
        language: form.language,
        timestamp: serverTimestampFunction(),
      };
      await set(textRef, newText);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Ocurrió un error inesperado.",
      }).then(() => {
        navigate("/actions/create-text");
      });
    } finally {
      handleReset();
      setLoading(false);
      Toast.fire({
        icon: "success",
        title: "Guardado correctamente.",
      }).then(() => {
        navigate("/actions/list-texts");
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!id) {
      if (
        form.text !== "" &&
        form.number >= 0 &&
        qaPairs.length > 0 &&
        form.language !== ""
      ) {
        await save();
      } else {
        Toast.fire({
          icon: "error",
          title: "Complete ambos campos y genere las preguntas para continuar.",
        }).then(() => {
          redirect("/actions/create-text");
        });
      }
    } else {
      await update();
    }
  };

  let title = id ? "Editar contenido del texto" : "Crear texto para lecturas";

  const handleGenerateQA = async () => {
    const url = "https://api.opexams.com/questions-generator/";
    const data = {
      type: "contextBased", //form.type,
      context: form.text, // form.type == "contextBased" ? form.text : form.topic,
      questionType: form.questionType,
      language: form.language,
      difficulty: form.difficulty,
    };
    const reqOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "api-key": "cvWAxH-cAkbfreE4wnTLwdCHba_Bkzl",
        credentials: "same-origin",
        //"request-type": "test",
      },
      body: JSON.stringify(data),
    };

    try {
      setIsGenerating(true);
      if (form.difficulty === "-1" || form.difficulty === undefined) {
        Toast.fire({
          icon: "error",
          title:
            "Por favor seleccione el nivel de dificultad de las preguntas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }

      if (form.text === "") {
        Toast.fire({
          icon: "error",
          title:
            "Por favor introdúzca el texto antes de generar las preguntas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      if (form.language === "") {
        Toast.fire({
          icon: "error",
          title:
            "Por favor introdúzca el idioma antes de generar las preguntas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      /* 
      if (form.type == "topicBased") {
        if (form.topic === "") {
          Toast.fire({
            icon: "error",
            title:
              "Por favor introdúzca el tema antes de generar las preguntas.",
          }).then(() => {
            redirect("/actions/create-text");
          });
          return;
        }
      } */
      if (form.number < 0) {
        Toast.fire({
          icon: "error",
          title:
            "Por favor introdúzca el límite de preguntas antes de generarlas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      if (form.number > max) {
        Toast.fire({
          icon: "error",
          title: "Límite de preguntas sobrepasado.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      const qaPairsResponse = await fetch(url, reqOptions);
      if (!qaPairsResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const res1 = await qaPairsResponse.json();
      if (form.number == 0) {
        setQAPairs(res1.data);
        setIsChecked(new Array(res1.data.length).fill(false));
        setForm({ ...form, number: res1.data.length });
        return;
      }
      const sliced = res1.data.slice(0, form.number);
      setQAPairs(sliced);
      setIsChecked(new Array(sliced.length).fill(false));
    } catch (error) {
      setError(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleBloomsApi = async () => {
    const url = process.env.REACT_APP_BLOOMS_API_URL;
    const levels = checkedLevels.toString();
    const data = {
      content: form.text, // form.type == "contextBased" ? form.text : form.topic,
      levels: levels,
      number: parseInt(form.number, 10),
      difficulty: form.difficulty,
      language: form.language,
      key: process.env.REACT_APP_BLOOMS_API_KEY,
    };
    console.log(form.language);
    const reqOptions = {
      method: "POST",
      body: JSON.stringify(data),
    };

    try {
      setIsGenerating(true);
      if (form.difficulty === "-1" || form.difficulty === undefined) {
        Toast.fire({
          icon: "error",
          title:
            "Por favor seleccione el nivel de dificultad de las preguntas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }

      if (form.text === "") {
        Toast.fire({
          icon: "error",
          title:
            "Por favor introdúzca el texto antes de generar las preguntas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      if (form.language === "") {
        Toast.fire({
          icon: "error",
          title:
            "Por favor introdúzca el idioma antes de generar las preguntas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      if (form.number <= 0) {
        Toast.fire({
          icon: "error",
          title:
            "Por favor introdúzca el límite de preguntas antes de generarlas.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      if (form.number > maxBlooms) {
        Toast.fire({
          icon: "error",
          title: "Límite de preguntas sobrepasado.",
        }).then(() => {
          redirect("/actions/create-text");
        });
        return;
      }
      const qaPairsResponse = await fetch(url, reqOptions);
      if (!qaPairsResponse.ok) {
        throw new Error("Network response was not ok");
      }
      // console.log(qaPairsResponse.text())
      const res1 = await qaPairsResponse.json();
      // const parsedContent = JSON.parse(res1);
      setIsGenerating(true);
      const ordered = res1.data.sort((a, b) => {
        const nivelA = levelsTexts.indexOf(a.level);
        const nivelB = levelsTexts.indexOf(b.level);

        return nivelA - nivelB;
      });
      setQAPairs(ordered);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const values = {
    limit: 2000000,
    title,
    form,
    handleChange,
    handleReset,
    handleSubmit,
    id,
    handleGenerateQA,
    handleBloomsApi,
    qaPairs,
    isGenerating,
    handleCheck,
    handleCheckLevels,
    checked,
    handleDelete,
    isChecked,
    isCheckedLevels,
  };

  if (isLoading || loading) {
    return <Loading />;
  }

  return <CreateText values={values} />;
};

export default CreateTextContainer;
