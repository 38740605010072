import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

const Header = () => {
	{/* <nav>
			<div className="nav-right">
			    <div className="drop-wrap">
					<Link
						href="/"
						id="drop1"
						className="link-c dropdown-toggle"
						data-toggle="dropdown"
						role="button">
						Idioma de la página: Español <i className="fa fa-chevron-down"></i>
						<div className="dropdown-content">
							<Link href="#">Ingles</Link>
						</div>
					</Link>
				</div> 
				<Dropdown className="drop-wrap">
					<Dropdown.Toggle  className="link-c dropdown-toggle" variant="success" id="dropdown-basic">
						Idioma de la página: Español 
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item href="#/spanish">Español </Dropdown.Item>
						<Dropdown.Item href="#/english">Inglés</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</nav> */}
	return (
		
		<Navbar variant="dark" bg="transparent" expand="lg">
			{/* <Container fluid>
				
				<Navbar.Toggle aria-controls="navbar-dark-example" className="ms-5 ms-auto"/>
				<Navbar.Collapse id="navbar-dark-example" className="text-link-1">
					<Nav className="ms-auto me-lg-5">
						<NavDropdown
						id="nav-dropdown-dark-example"
						title="Idioma"
						menuVariant="light"
						className="me-lg-5 mx-auto"
						>
							<NavDropdown.Item href="#action/3.1"  className="text-link-1">Español</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.2"  className="text-link-1">Inglés</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container> */}
		</Navbar>
	);
};
export default Header;
