import { useState, useEffect } from "react";
import { database } from "../config/firebase";
import {
  ref,
  get,
  set,
  update,
  remove,
  child,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { UserAuth } from "../context/AuthContext";

const useFetchTexts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const textsRef = ref(database, "users/" + user.uid + "/texts");
        onValue(textsRef, (snapshot) => {
          let dataItem;
          let arr = [];
          snapshot.forEach((item) => {
            dataItem = item.val();
            dataItem.id = item.key;
            arr.push(dataItem);
          });
          setData(arr);
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchResource();
  }, []);

  return { data, loading, error };
};

export default useFetchTexts;
