import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const Terms = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Política de Privacidad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-3">
          <section className="mb-5">
            <h2>Introducción</h2>
            <p>
              ¡Bienvenido a MiABC! Estamos dedicados a proporcionar una
              experiencia de aprendizaje segura y agradable para los niños.
              Proteger la privacidad de su hijo es una prioridad para nosotros.
              Esta Política de Privacidad explica cómo recopilamos, usamos y
              protegemos la información personal de los niños menores de 13 años
              que usan nuestra aplicación. Al usar MiABC, usted acepta los
              términos descritos en esta política.
            </p>
          </section>

          <section className="mb-5">
            <h2>Información que Recopilamos</h2>

            <h3>1. Información Personal</h3>
            <p>
              No recopilamos información personal directamente de los niños sin
              el consentimiento de los padres. La información personal puede
              incluir:
            </p>
            <ul>
              <li>
                Información de Contacto del Padre/Tutor: Dirección de correo
                electrónico o número de teléfono proporcionados durante el
                registro de la cuenta para habilitar la comunicación y
                verificación del consentimiento.
              </li>
              <li>
                Información del Niño: Nombre y edad, proporcionados por los
                padres o tutores durante la configuración de la cuenta para
                personalizar la experiencia de aprendizaje.
              </li>
            </ul>

            <h3>2. Información No Personal</h3>
            <p>
              Podemos recopilar información no personal para mejorar la
              funcionalidad y la experiencia del usuario en la aplicación. Esto
              incluye:
            </p>
            <ul>
              <li>
                Información del Dispositivo: Tipo de dispositivo, sistema
                operativo e identificadores únicos del dispositivo.
              </li>
              <li>
                Datos de Uso: Información sobre cómo se utiliza la aplicación,
                incluyendo las funciones accesadas, el tiempo pasado en la
                aplicación y el progreso del aprendizaje.
              </li>
            </ul>
          </section>

          <section className="mb-5">
            <h2>Cómo Usamos la Información</h2>

            <h3>1. Información Personal</h3>
            <p>
              La información personal se utiliza únicamente para los siguientes
              propósitos:
            </p>
            <ul>
              <li>
                Gestión de Cuentas: Crear y gestionar cuentas de usuario,
                verificar el consentimiento de los padres y proporcionar soporte
                al cliente.
              </li>
              <li>
                Personalización: Adaptar la experiencia de aprendizaje según la
                edad y preferencias del niño.
              </li>
              <li>
                Comunicación: Enviar actualizaciones importantes y
                notificaciones a los padres o tutores.
              </li>
            </ul>

            <h3>2. Información No Personal</h3>
            <p>
              La información no personal nos ayuda a mejorar la aplicación y
              asegurar una mejor experiencia de usuario mediante:
            </p>
            <ul>
              <li>
                Análisis de Tendencias de Uso: Comprender cómo los usuarios
                interactúan con la aplicación para mejorar las funciones y la
                funcionalidad.
              </li>
              <li>
                Soporte Técnico: Identificar y solucionar problemas técnicos.
              </li>
              <li>
                Investigación y Desarrollo: Desarrollar nuevas características y
                contenido educativo basado en el comportamiento y las opiniones
                de los usuarios.
              </li>
            </ul>
          </section>

          <section className="mb-5">
            <h2>Seguridad de los Datos</h2>
            <p>
              Estamos comprometidos a proteger la información de su hijo.
              Implementamos medidas de seguridad estándar en la industria para
              proteger la información personal contra el acceso no autorizado,
              la alteración, la divulgación o la destrucción. Estas medidas
              incluyen cifrado, servidores seguros y evaluaciones de seguridad
              regulares. Sin embargo, tenga en cuenta que ningún método de
              transmisión por Internet o almacenamiento electrónico es 100%
              seguro.
            </p>
          </section>

          <section className="mb-5">
            <h2>Consentimiento Parental</h2>
            <p>
              Requerimos el consentimiento parental verificable antes de
              recopilar, usar o divulgar información personal de niños menores
              de 13 años. Los padres o tutores pueden proporcionar su
              consentimiento a través de varios métodos, incluyendo:
            </p>
            <ul>
              <li>
                Consentimiento por Correo Electrónico: Envío de un formulario de
                consentimiento firmado por correo electrónico.
              </li>
              <li>
                Consentimiento por Pago: Uso de una transacción con tarjeta de
                crédito para verificar la identidad y el consentimiento.
              </li>
              <li>
                Formulario de Consentimiento: Completar un formulario de
                consentimiento en línea.
              </li>
            </ul>
            <p>
              Los padres tienen el derecho de revisar, corregir o eliminar la
              información personal de su hijo en cualquier momento
              contactándonos en miabc.app@gmail.com.
            </p>
          </section>

          <section className="mb-5">
            <h2>Compartición de Información</h2>
            <p>
              No compartimos información personal con terceros, excepto en las
              siguientes circunstancias:
            </p>
            <ul>
              <li>
                Proveedores de Servicios: Proveedores de servicios de terceros
                confiables que nos ayudan a operar la aplicación, siempre que
                cumplan con nuestras políticas de protección de datos.
              </li>
              <li>
                Requisitos Legales: Cuando lo requiera la ley o para proteger la
                seguridad y los derechos de nuestros usuarios.
              </li>
            </ul>
          </section>

          <section className="mb-5">
            <h2>Enlaces a Terceros</h2>
            <p>
              MiABC puede contener enlaces a sitios web o servicios de terceros.
              Estos no están regidos por esta Política de Privacidad. Animamos a
              los padres a revisar las políticas de privacidad de estos terceros
              antes de permitir que sus hijos los accedan.
            </p>
          </section>

          <section className="mb-5">
            <h2>Cambios a Esta Política de Privacidad</h2>
            <p>
              Podemos actualizar esta Política de Privacidad de vez en cuando.
              Los padres serán notificados de cualquier cambio significativo por
              correo electrónico o a través de un aviso dentro de la aplicación.
              El uso continuado de la aplicación después de dichos cambios
              constituirá el reconocimiento y la aceptación de la política
              actualizada.
            </p>
          </section>

          <section className="mb-5">
            <h2>Contáctenos</h2>
            <p>
              Si tiene alguna pregunta o inquietud sobre esta Política de
              Privacidad, por favor contáctenos en:
            </p>
            <ul>
              <li>Email: miabc.app@gmail.com</li>
              {/* <li>
                Dirección: Departamento de Privacidad de MiABC, 123 Learning
                Lane, Education City, EC 45678
              </li> */}
            </ul>
            <p>
              Gracias por confiar en MiABC con el aprendizaje de su hijo.
              Estamos comprometidos a asegurar una experiencia segura y
              enriquecedora para todos los usuarios.
            </p>
          </section>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Terms;
