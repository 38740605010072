import { useState, useEffect } from "react";
import { database } from "../config/firebase";
import {
  ref,
  get,
  set,
  update,
  remove,
  child,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { UserAuth } from "../context/AuthContext";

const useFetch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const userRef = ref(database, "users/" + user.uid + "/accessCode");
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          const res = query(
            ref(database, "users"),
            orderByChild("accessCode"),
            equalTo(data)
          );
          onValue(res, (snapshot) => {
            if (snapshot.exists()) {
              let dataItem;
              snapshot.forEach((item) => {
                if (item.val().username) {
                  dataItem = item.val();
                  dataItem.id = item.key;
                }
              });
              setData(dataItem);
              setLoading(false);
            }
          });
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchResource();
  }, []);

  return { data, loading, error };
};

export default useFetch;
