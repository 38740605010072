import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';

const Step1 = ({ defaultValues, next, step }) => {
	const { handleSubmit, watch, setValue  } = useForm({
		defaultValues
	});
	const role = watch('role');
	const [required, setRequired] = useState(null)
	const [select, setSelect] = useState('');

	const onSubmit = (data) => {
		if(!data.role){
			/* alert("El avatar es obligatorio"); */
			setRequired("El rol es requerido");
			return;
		}
		next(data, step);
	};

	const handleChange = (e) => {
		setValue('role', e.target.value);
		
	}

	return (
		<div className="login-content">
			<form onSubmit={handleSubmit(onSubmit)}>
				<img src="/assets/img/logo-02.svg" alt="" />
				<h3 className="title">¡Crea una cuenta!</h3>
				{/* <label htmlFor="user">Selecciona tu rol </label>
				<select name="Usuario" id="usuario">
					<option value="Padre">Padre</option>
					<option value="Maestro">Maestro</option>
				</select> */}
				<Form.Select aria-label="select" onChange={handleChange} value={role ? role : ''}>
						<option value="">Selecciona tu rol</option>
						<option value="Padre">Padre</option>
						{/* <option value="Maestro">Maestro</option> */}
				</Form.Select>
				{/* 
				<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>Selecciona tu rol </Form.Label>
					<Form.Select aria-label="select">
						<option value="Padre">Padre</option>
						<option value="Maestro">Maestro</option>
					</Form.Select>
				</Form.Group> */}
				<div className='text-danger'>{required ? required : ''}</div>
				<input type="submit" className="btn" value="Siguiente" />
				<Link to="/">
					Ya tienes una cuenta? <strong>Inicia Sesión.</strong>
				</Link>
			</form>
		</div>
	);
};

export default Step1;
