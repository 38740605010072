import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { auth } from '../../config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { UserAuth } from '../../context/AuthContext';
import Loading from '../../components/Loading';

const RegisterPage = () => {
	const { step } = useParams();
	const [state, setState] = useState({});
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	/* const [loading, setLoading] = useState(null); */

	const navigate = useNavigate();
	const { createUser, user, percent, loading} = UserAuth();

	
	const register = async (data) => {				
		try {
			// setLoading(true);
			
			/* console.log("Porcentaje: ", percent); */
			await createUser(data);
			// setLoading(false);
			/* user.updateProfile({
			  displayName: data
			}); */
		
			return {};
		  } catch (error) {
			switch (error.code) {
			  case "auth/email-already-in-use":
				return {
				  error: "Correo electronico ya en uso."
				};
			  case "auth/invalid-email":
				return {
				  error: "Correo invalido."
				};
			  case "auth/weak-password":
				return {
				  error: "Contraseña es débil."
				};
			  case "auth/too-many-requests":
				return {
				  error: "Trate de nuevo en un minuto."
				};
			  default:
				return {
				  error: "Chequee su conexion."
				};
			}
		  }
	}

	const onSubmit = (data, step) => {
		setState({ ...state, ...data });
		if (step === 'step1') {
			navigate('/register/step2');
		}

		if (step === 'step2') {
			
			navigate('/register/step3');
		}

		if (step === 'step3') {
			/* window.alert('Finished'); */
			
			register(data);

		}
	};
	if(loading){
		return <Loading/>
	}
	switch (step) {
		case 'step1':
			return <Step1 step={step} defaultValues={state} next={onSubmit} />;
		case 'step2':
			return <Step2 step={step} defaultValues={state} next={onSubmit} />;
		case 'step3':
			return <Step3 step={step} defaultValues={state} next={onSubmit} />;
		default:
			return <Loading/>;
	}
};

export default RegisterPage;
