import React from "react";
import Header from "../../components/protected/Header";
import { BiInfoCircle, BiTrash, BiTrashAlt } from "react-icons/bi";
import Accordion from "react-bootstrap/Accordion";
import { generatePDF } from "../../components/utils/Pdf";

const CreateText = ({ values }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
        <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{values.title}</h1>
          </div>
          <div className="d-flex mb-5 justify-content-center">
            <div className="w-lg-50 w-75">
              <label
                htmlFor="text"
                className="form-label fw-bold fs-5 text-center mb-4"
              >
                Seleccione el formato
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={values.form.format}
                onChange={values.handleChange}
                name="format"
              >
                <option value="-1" defaultValue>
                  Seleccionar
                </option>
                <option value="open">Abierto</option>
                <option value="blooms">Taxonomía Blooms</option>
              </select>
              <div id="helpText3" className="form-text">
                Seleccione el formato del questionario, puede ser abierto o
                basado en taxonomía blooms.
              </div>
            </div>
          </div>

          {values.form.format == "open" && (
            <>
              <div
                className="alert alert-info text-center w-100 d-flex justify-content-center"
                role="alert"
              >
                <BiInfoCircle size="24" className=" mx-2" />
                En este módulo podrás generar un conjunto de preguntas y sus
                respuestas, a partir de un texto.
              </div>
              <div className="d-flex mt-5 justify-content-center">
                <form className="w-lg-50 w-75" onSubmit={values.handleSubmit}>
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Introduzca el texto
                    </label>
                    <textarea
                      className="form-control"
                      id="text"
                      rows="7"
                      name="text"
                      maxLength={values.limit}
                      onChange={values.handleChange}
                      value={values.form.text}
                    ></textarea>
                    <div id="helpText" className="form-text">
                      Introduzca un texto a partir del cual se generarán las
                      preguntas.
                    </div>
                  </div>
                  {/*  <div className="mb-5">
                <label
                  htmlFor="text"
                  className="form-label fw-bold fs-5 text-center mb-4"
                >
                  Seleccione el tipo de entrada
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={values.form.type}
                  onChange={values.handleChange}
                  name="type"
                >
                  <option value="contextBased" selected>
                    Texto
                  </option>
                  <option value="topicBased">Tema</option>
                </select>
              </div> */}
                  {/* {values.form.type == "contextBased" && (
                <div className="mb-5">
                  <label
                    htmlFor="text"
                    className="form-label fw-bold fs-5 text-center mb-4"
                  >
                    Introduzca el texto
                  </label>
                  <textarea
                    className="form-control"
                    id="text"
                    rows="7"
                    name="text"
                    maxLength={values.limit}
                    onChange={values.handleChange}
                    value={values.form.text}
                  ></textarea>
                  <div id="helpText" className="form-text">
                    Introduzca un texto a partir del cual se generarán las
                    preguntas.
                  </div>
                </div>
              )}
              {values.form.type == "topicBased" && (
                <div className="mb-5">
                  <label
                    htmlFor="text"
                    className="form-label fw-bold fs-5 text-center mb-4"
                  >
                    Introduzca el Tema
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="type"
                    name="topic"
                    onChange={values.handleChange}
                    value={values.form.topic}
                  />
                  <div id="helpText" className="form-text">
                    Introduzca un tema a partir del cual desea generar las
                    preguntas (Ej: <i>Aprendizaje</i>).
                  </div>
                </div>
              )} */}
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Seleccione el tipo de pregunta
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={values.form.questionType}
                      onChange={values.handleChange}
                      name="questionType"
                    >
                      <option value="-1" defaultValue>
                        Seleccionar
                      </option>
                      <option value="MCQ">Seleccion Múltiple</option>
                      <option value="TF">Verdadero o Falso</option>
                      <option value="open">Respuesta Abierta</option>
                    </select>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Seleccione el nivel de dificultad de pregunta
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={values.form.difficulty}
                      onChange={values.handleChange}
                      name="difficulty"
                    >
                      <option value="-1" defaultValue>
                        Seleccionar
                      </option>
                      <option value="fácil">Fácil</option>
                      <option value="medio">Medio</option>
                      <option value="difícil">Difícil</option>
                    </select>
                  </div>
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="number"
                      className="form-label fs-5 fw-bold text-center mb-4"
                    >
                      Limitar cantidad de preguntas a:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number"
                      name="number"
                      min={0}
                      max={20}
                      onChange={values.handleChange}
                      value={values.form.number || 0}
                    />

                    <div id="helpNumber" className="form-text">
                      Introduzca el número máximo de preguntas que desea
                      generar, 0 generará el máximo posible.
                    </div>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Seleccione el idioma
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={values.form.language}
                      onChange={values.handleChange}
                      name="language"
                    >
                      <option value="-1" defaultValue>
                        Seleccionar
                      </option>
                      <option value="Spanish">Español</option>
                      <option value="English">Inglés</option>
                    </select>
                    <div id="helpNumber" className="form-text">
                      Seleccione el idioma en el que quiere los resultados.
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">
                      <button
                        disabled={values.isGenerating}
                        onClick={values.handleGenerateQA}
                        type="button"
                        className="btn-4 btn-sm btn-outline-secondary px-5 py-0 py-md-2 w-100 mt-3"
                      >
                        {values.isGenerating
                          ? "Generando..."
                          : values.qaPairs &&
                            values.qaPairs &&
                            Array.isArray(values.qaPairs) &&
                            values.qaPairs.length > 0
                          ? "Volver a generar"
                          : "Generar preguntas y respuestas"}
                      </button>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {/* <div className="col-12 col-lg-10 col-xl-8">
                      <button
                        disabled={values.isGenerating}
                        onClick={() =>
                          openaiComponent(
                            values.form.text,
                            values.form.number,
                            values.form.difficulty,
                            "crear"
                          )
                        }
                        type="button"
                        className="btn-4 btn-sm btn-outline-secondary px-5 py-0 py-md-2 w-100 mt-3"
                      >
                        OpenAi
                      </button>
                    </div> */}
                  </div>

                  <div
                    className={`${
                      values.qaPairs &&
                      values.qaPairs &&
                      Array.isArray(values.qaPairs) &&
                      values.qaPairs.length > 0
                        ? "mb-2"
                        : "mb-3"
                    }   `}
                  >
                    <div className="questionsAccordion card my-3">
                      {values.checked.length === 0 &&
                        values.qaPairs &&
                        values.qaPairs &&
                        Array.isArray(values.qaPairs) &&
                        values.qaPairs.length > 0 && (
                          <>
                            <h4 className="text-center mt-2">
                              <span className="fw-bold">Resultados:</span>{" "}
                            </h4>{" "}
                            <div
                              id="helpText"
                              className="form-text text-center mb-2"
                            >
                              Haga clic en las preguntas para desplegar el
                              detalle. Haga clic en las casillas si desea
                              eliminar alguna pregunta.
                            </div>
                          </>
                        )}

                      {values.checked.length > 0 && (
                        <div className="d-flex justify-content-between gap-1 ms-3 me-4">
                          <div>
                            {values.qaPairs &&
                              values.qaPairs &&
                              Array.isArray(values.qaPairs) &&
                              values.qaPairs.length > 0 && (
                                <>
                                  <h4 className="text-center mt-3">
                                    <span className="fw-bold">Resultados:</span>{" "}
                                  </h4>
                                </>
                              )}
                          </div>
                          <div>
                            {values.qaPairs &&
                              values.qaPairs &&
                              Array.isArray(values.qaPairs) &&
                              values.qaPairs.length > 0 && (
                                <button
                                  onClick={values.handleDelete}
                                  type="button"
                                  className="mt-2 delete-checked px-2 py-0 py-md-2 w-100 my-2"
                                >
                                  <div className="d-flex gap-1">
                                    <BiTrash size={20} className="mt-1" />
                                    <span className="fs-5">
                                      Remover seleccionados
                                    </span>
                                  </div>
                                </button>
                              )}
                          </div>
                        </div>
                      )}
                      <Accordion defaultActiveKey="0" flush>
                        {values.qaPairs &&
                        values.qaPairs &&
                        Array.isArray(values.qaPairs) &&
                        values.qaPairs.length > 0 ? (
                          values.qaPairs.map((element, index) => (
                            <div
                              key={index}
                              className="form-check ms-3 checked-text"
                            >
                              <input
                                className="form-check-input mt-3"
                                type="checkbox"
                                name="toDelete[]"
                                checked={values.isChecked[index]}
                                id="flexCheckDefault"
                                value={element.id ? element.id : index}
                                onChange={(event) =>
                                  values.handleCheck(event, index)
                                }
                              />
                              <Accordion.Item eventKey={index} className="my-3">
                                <Accordion.Header>
                                  <span className="fs-5 fw-bold me-2">
                                    {index + 1}. {element.question}
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {values.form.questionType === "MCQ" &&
                                    element.options &&
                                    Array.isArray(element.options) && (
                                      <div>
                                        <p className="fs-5 fw-bold">
                                          Opciones:
                                        </p>
                                        {element.options.map((op, i) => (
                                          <div key={i}>
                                            <span className="fs-6">- {op}</span>
                                            <br />
                                          </div>
                                        ))}
                                        <p className="fs-5 fw-bold mt-2">
                                          Respuesta:
                                        </p>
                                        <p className="fs-5 fw-bold text-success">
                                          {element.answer}
                                        </p>
                                      </div>
                                    )}
                                  {values.form.questionType === "TF" && (
                                    <div>
                                      <p className="fs-5  mt-2">
                                        Respuesta:{" "}
                                        {element.answer === true ? (
                                          <span className="fw-bold text-success">
                                            Verdadero
                                          </span>
                                        ) : (
                                          <span className="fw-bold text-danger">
                                            Falso
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  {values.form.questionType === "open" && (
                                    <div>
                                      <p className="fs-5  mt-2">
                                        <p className="text-center">
                                          Respuesta:
                                        </p>
                                        <span className="fw-bold text-success text-center">
                                          {element.answer}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </div>
                          ))
                        ) : (
                          <div
                            id="helpNumber"
                            className="form-text text-center"
                          >
                            <p>
                              No se han generado preguntas y respuestas aún.
                            </p>
                          </div>
                        )}
                      </Accordion>
                      {values.qaPairs &&
                        Array.isArray(values.qaPairs) &&
                        values.qaPairs.length > 0 && (
                          <div className="d-flex justify-content-between">
                            <button
                              onClick={() => generatePDF(values.qaPairs)}
                              type="button"
                              className="btn-3 btn-sm btn-outline-secondary px-5 py-0 py-md-2 mt-3"
                              disabled={values.qaPairs.length === 0}
                            >
                              Generar e Imprimir PDF
                            </button>
                            <button
                              onClick={() => generatePDF(values.qaPairs, false)}
                              type="button"
                              className="btn-3 btn-sm btn-outline-secondary px-5 py-0 py-md-2 mt-3"
                              disabled={values.qaPairs.length === 0}
                            >
                              Generar e Imprimir PDF sin respuestas
                            </button>
                          </div>
                        )}
                    </div>
                    {/* <ul className="list-group list-group-flush">
                  {values.qaPairs &&
                  values.qaPairs &&
                  Array.isArray(values.qaPairs) &&
                  values.qaPairs.length > 0 ? (
                    values.qaPairs.map((element, index) => (
                      <li className="list-group-item" key={index}>
                        <div>
                          <p className="fs-4 fw-bold">
                            {index + 1}. {element.question}
                          </p>
                          {values.form.questionType === "MCQ" &&
                            element.options &&
                            Array.isArray(element.options) && (
                              <div>
                                <p className="fs-5 fw-bold">Opciones:</p>
                                {element.options.map((op, i) => (
                                  <div key={i}>
                                    <span className="fs-6">- {op}</span>
                                    <br />
                                  </div>
                                ))}
                                <p className="fs-5 fw-bold mt-2">Respuesta:</p>
                                <p className="fs-5 fw-bold text-success">
                                  {element.answer}
                                </p>
                              </div>
                            )}
                          {values.form.questionType === "TF" && (
                            <div>
                              <p className="fs-5  mt-2">
                                Respuesta:{" "}
                                {element.answer === true ? (
                                  <span className="fw-bold text-success">
                                    Verdadero
                                  </span>
                                ) : (
                                  <span className="fw-bold text-danger">
                                    Falso
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </li>
                    ))
                  ) : (
                    <div id="helpNumber" className="form-text text-center">
                      <p>No se han generado preguntas y respuestas aún.</p>
                    </div>
                  )}
                </ul> */}
                  </div>
                  {/* <div className="questionsAccordion">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="fs-4 fw-bold">Accordion Item #1</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      <span className="fs-4 fw-bold">Accordion Item #1</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div> */}
                  {values.qaPairs &&
                    values.qaPairs &&
                    Array.isArray(values.qaPairs) &&
                    values.qaPairs.length > 0 && (
                      <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                          <button
                            disabled={values.isGenerating}
                            onClick={values.handleGenerateQA}
                            type="button"
                            className="btn-4 btn-sm btn-outline-secondary px-5 py-0 py-md-2 w-100 my-2 mb-5"
                          >
                            {values.isGenerating
                              ? "Generando..."
                              : "Volver a generar"}
                          </button>
                        </div>
                      </div>
                    )}
                  <div className="row justify-content-evenly">
                    <div className="col-12 col-lg-4">
                      <button
                        disabled={values.isGenerating}
                        type="submit"
                        className="btn-4 btn-sm btn-outline-secondary px-5 pt-2 w-100"
                      >
                        <p className="text-center">
                          {values.id ? "Actualizar" : "Guardar"}
                        </p>
                      </button>
                    </div>
                    <div className="col-12 col-lg-4">
                      <button
                        disabled={values.isGenerating}
                        type="button"
                        onClick={values.handleReset}
                        className="btn-3 btn-sm btn-outline-secondary px-5 pt-2 w-100"
                      >
                        <p className="text-center">
                          {values.id ? "Restablecer" : "Borrar"}
                        </p>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {values.form.format == "blooms" && (
            <>
              <div
                className="alert alert-info text-center w-100 d-flex justify-content-center"
                role="alert"
              >
                <BiInfoCircle size="24" className=" mx-2" />
                En este módulo podrás generar un conjunto de preguntas y sus
                respuestas, a partir de un texto y basado en la taxonomía booms.
              </div>
              <div className="d-flex mt-5 justify-content-center">
                <form className="w-lg-50 w-75" onSubmit={values.handleSubmit}>
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Introduzca el texto
                    </label>
                    <textarea
                      className="form-control"
                      id="text"
                      rows="7"
                      name="text"
                      maxLength={values.limit}
                      onChange={values.handleChange}
                      value={values.form.text}
                    ></textarea>
                    <div id="helpText" className="form-text">
                      Introduzca un texto a partir del cual se generarán las
                      preguntas.
                    </div>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Seleccione los niveles
                    </label>
                    <div className="row mx-1">
                      <div className="form-check checked-text col-6 col-md-3">
                        <input
                          name="levels"
                          className="form-check-input"
                          type="checkbox"
                          value="crear"
                          id="flexCheckDefault"
                          onChange={(event) =>
                            values.handleCheckLevels(event, 0)
                          }
                          checked={values.isCheckedLevels[0]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Crear
                        </label>
                      </div>
                      <div className="form-check checked-text col-6 col-md-3">
                        <input
                          name="levels"
                          className="form-check-input"
                          type="checkbox"
                          value="evaluar"
                          id="flexCheckChecked2"
                          onChange={(event) =>
                            values.handleCheckLevels(event, 1)
                          }
                          checked={values.isCheckedLevels[1]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked2"
                        >
                          Evaluar
                        </label>
                      </div>
                      <div className="form-check checked-text col-6 col-md-3">
                        <input
                          name="levels"
                          className="form-check-input"
                          type="checkbox"
                          value="analizar"
                          id="flexCheckChecked3"
                          onChange={(event) =>
                            values.handleCheckLevels(event, 2)
                          }
                          checked={values.isCheckedLevels[2]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked3"
                        >
                          Analizar
                        </label>
                      </div>
                      <div className="form-check checked-text col-6 col-md-3">
                        <input
                          name="levels"
                          className="form-check-input"
                          type="checkbox"
                          value="aplicar"
                          id="flexCheckChecked4"
                          onChange={(event) =>
                            values.handleCheckLevels(event, 3)
                          }
                          checked={values.isCheckedLevels[3]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked4"
                        >
                          Aplicar
                        </label>
                      </div>
                      <div className="form-check checked-text col-6 col-md-3">
                        <input
                          name="levels"
                          className="form-check-input"
                          type="checkbox"
                          value="entender"
                          id="flexCheckChecked5"
                          onChange={(event) =>
                            values.handleCheckLevels(event, 4)
                          }
                          checked={values.isCheckedLevels[4]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked5"
                        >
                          Entender
                        </label>
                      </div>
                      <div className="form-check checked-text col-6 col-md-3">
                        <input
                          name="levels"
                          className="form-check-input"
                          type="checkbox"
                          value="recordar"
                          id="flexCheckChecked6"
                          onChange={(event) =>
                            values.handleCheckLevels(event, 5)
                          }
                          checked={values.isCheckedLevels[5]}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked6"
                        >
                          Recordar
                        </label>
                      </div>
                    </div>
                    <div id="helpNumber" className="form-text">
                      Seleccione los niveles que desea que sean generados en las
                      preguntas, no seleccionar ningún nivel generará preguntas
                      para todos.
                    </div>
                  </div>

                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Seleccione el nivel de dificultad de pregunta
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={values.form.difficulty}
                      onChange={values.handleChange}
                      name="difficulty"
                    >
                      <option value="-1" defaultValue>
                        Seleccionar
                      </option>
                      <option value="fácil">Fácil</option>
                      <option value="medio">Medio</option>
                      <option value="difícil">Difícil</option>
                    </select>
                  </div>
                  <div className="mb-3 mt-3">
                    <label
                      htmlFor="number"
                      className="form-label fs-5 fw-bold text-center mb-4"
                    >
                      Limitar cantidad de preguntas a:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number"
                      name="number"
                      min={1}
                      max={5}
                      onChange={values.handleChange}
                      value={values.form.number || 0}
                    />

                    <div id="helpNumber" className="form-text">
                      Introduzca el número máximo de preguntas que desea generar
                      por nivel, máximo 5.
                    </div>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="text"
                      className="form-label fw-bold fs-5 text-center mb-4"
                    >
                      Seleccione el idioma
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={values.form.language}
                      onChange={values.handleChange}
                      name="language"
                    >
                      <option value="-1" defaultValue>
                        Seleccionar
                      </option>
                      <option value="Spanish">Español</option>
                      <option value="English">Inglés</option>
                    </select>
                    <div id="helpNumber" className="form-text">
                      Seleccione el idioma en el que quiere los resultados.
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">
                      <button
                        disabled={values.isGenerating}
                        onClick={values.handleBloomsApi}
                        type="button"
                        className="btn-4 btn-sm btn-outline-secondary px-5 py-0 py-md-2 w-100 mt-3"
                      >
                        {values.isGenerating
                          ? "Generando..."
                          : values.qaPairs &&
                            values.qaPairs &&
                            Array.isArray(values.qaPairs) &&
                            values.qaPairs.length > 0
                          ? "Volver a generar"
                          : "Generar preguntas y respuestas"}
                      </button>
                    </div>
                  </div>
                  {/* <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">
                      <button
                        disabled={values.isGenerating}
                        onClick={() =>
                          openaiComponent(
                            values.form.text,
                            values.form.number,
                            values.form.difficulty,
                            "crear"
                          )
                        }
                        type="button"
                        className="btn-4 btn-sm btn-outline-secondary px-5 py-0 py-md-2 w-100 mt-3"
                      >
                        OpenAi
                      </button>
                    </div>
                  </div> */}

                  <div
                    className={`${
                      values.qaPairs &&
                      values.qaPairs &&
                      Array.isArray(values.qaPairs) &&
                      values.qaPairs.length > 0
                        ? "mb-2"
                        : "mb-3"
                    }   `}
                  >
                    <div className="questionsAccordion card my-3">
                      {values.checked.length === 0 &&
                        values.qaPairs &&
                        values.qaPairs &&
                        Array.isArray(values.qaPairs) &&
                        values.qaPairs.length > 0 && (
                          <>
                            <h4 className="text-center mt-2">
                              <span className="fw-bold">Resultados:</span>{" "}
                            </h4>{" "}
                            <div
                              id="helpText"
                              className="form-text text-center mb-2"
                            >
                              Haga clic en las preguntas para desplegar el
                              detalle. Haga clic en las casillas si desea
                              eliminar alguna pregunta.
                            </div>
                          </>
                        )}

                      {values.checked.length > 0 && (
                        <div className="d-flex justify-content-between gap-1 ms-3 me-4">
                          <div>
                            {values.qaPairs &&
                              values.qaPairs &&
                              Array.isArray(values.qaPairs) &&
                              values.qaPairs.length > 0 && (
                                <>
                                  <h4 className="text-center mt-3">
                                    <span className="fw-bold">Resultados:</span>{" "}
                                  </h4>
                                </>
                              )}
                          </div>
                          <div>
                            {values.qaPairs &&
                              values.qaPairs &&
                              Array.isArray(values.qaPairs) &&
                              values.qaPairs.length > 0 && (
                                <button
                                  onClick={values.handleDelete}
                                  type="button"
                                  className="mt-2 delete-checked px-2 py-0 py-md-2 w-100 my-2"
                                >
                                  <div className="d-flex gap-1">
                                    <BiTrash size={20} className="mt-1" />
                                    <span className="fs-5">
                                      Remover seleccionados
                                    </span>
                                  </div>
                                </button>
                              )}
                          </div>
                        </div>
                      )}
                      <Accordion defaultActiveKey="0" flush>
                        {values.qaPairs &&
                        values.qaPairs &&
                        Array.isArray(values.qaPairs) &&
                        values.qaPairs.length > 0 ? (
                          values.qaPairs.map((element, index) => (
                            <div
                              key={index}
                              className="form-check ms-3 me-3 checked-text"
                            >
                              <hr />
                              <p className="fs-5 text-start my-3 text-primary">
                                {element.level.toUpperCase()}
                              </p>
                              <input
                                className="form-check-input mt-3"
                                type="checkbox"
                                name="toDelete[]"
                                checked={values.isChecked[index]}
                                id="flexCheckDefault"
                                value={element.id ? element.id : index}
                                onChange={(event) =>
                                  values.handleCheck(event, index)
                                }
                              />

                              <Accordion.Item eventKey={index} className="my-3">
                                <Accordion.Header>
                                  <span className="fs-5 fw-bold me-2">
                                    {index + 1}. {element.question}
                                  </span>
                                </Accordion.Header>
                                {element.level && (
                                  <Accordion.Body>
                                    {element.options &&
                                      Array.isArray(element.options) && (
                                        <div>
                                          <p className="fs-5 fw-bold">
                                            Opciones:
                                          </p>
                                          {element.options.map((op, i) => (
                                            <div key={i}>
                                              <span className="fs-6">
                                                - {op}
                                              </span>
                                              <br />
                                            </div>
                                          ))}
                                          <p className="fs-5 fw-bold mt-2">
                                            Respuesta:
                                          </p>
                                          <p className="fs-5 fw-bold text-success">
                                            {element.answer}
                                          </p>
                                        </div>
                                      )}
                                  </Accordion.Body>
                                )}

                                {!element.level && (
                                  <Accordion.Body>
                                    {values.form.questionType === "MCQ" &&
                                      element.options &&
                                      Array.isArray(element.options) && (
                                        <div>
                                          <p className="fs-5 fw-bold">
                                            Opciones:
                                          </p>
                                          {element.options.map((op, i) => (
                                            <div key={i}>
                                              <span className="fs-6">
                                                - {op}
                                              </span>
                                              <br />
                                            </div>
                                          ))}
                                          <p className="fs-5 fw-bold mt-2">
                                            Respuesta:
                                          </p>
                                          <p className="fs-5 fw-bold text-success">
                                            {element.answer}
                                          </p>
                                        </div>
                                      )}
                                    {values.form.questionType === "TF" && (
                                      <div>
                                        <p className="fs-5  mt-2">
                                          Respuesta:{" "}
                                          {element.answer === true ? (
                                            <span className="fw-bold text-success">
                                              Verdadero
                                            </span>
                                          ) : (
                                            <span className="fw-bold text-danger">
                                              Falso
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    {values.form.questionType === "open" && (
                                      <div>
                                        <p className="fs-5  mt-2">
                                          <p className="text-center">
                                            Respuesta:
                                          </p>
                                          <span className="fw-bold text-success text-center">
                                            {element.answer}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </Accordion.Body>
                                )}
                              </Accordion.Item>
                            </div>
                          ))
                        ) : (
                          <div
                            id="helpNumber"
                            className="form-text text-center"
                          >
                            <p>
                              No se han generado preguntas y respuestas aún.
                            </p>
                          </div>
                        )}
                        {values.qaPairs &&
                          Array.isArray(values.qaPairs) &&
                          values.qaPairs.length > 0 && (
                            <div className="d-flex justify-content-between">
                              <button
                                onClick={() => generatePDF(values.qaPairs)}
                                type="button"
                                className="btn-3 btn-sm btn-outline-secondary px-5 py-0 py-md-2 mt-3"
                                disabled={values.qaPairs.length === 0}
                              >
                                Generar e Imprimir PDF
                              </button>
                              <button
                                onClick={() =>
                                  generatePDF(values.qaPairs, false)
                                }
                                type="button"
                                className="btn-3 btn-sm btn-outline-secondary px-5 py-0 py-md-2 mt-3"
                                disabled={values.qaPairs.length === 0}
                              >
                                Generar e Imprimir PDF sin respuestas
                              </button>
                            </div>
                          )}
                      </Accordion>
                    </div>
                  </div>
                  {values.qaPairs &&
                    values.qaPairs &&
                    Array.isArray(values.qaPairs) &&
                    values.qaPairs.length > 0 && (
                      <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                          <button
                            disabled={values.isGenerating}
                            onClick={values.handleGenerateQA}
                            type="button"
                            className="btn-4 btn-sm btn-outline-secondary px-5 py-0 py-md-2 w-100 my-2 mb-5"
                          >
                            {values.isGenerating
                              ? "Generando..."
                              : "Volver a generar"}
                          </button>
                        </div>
                      </div>
                    )}
                  <div className="row justify-content-evenly">
                    <div className="col-12 col-lg-4">
                      <button
                        disabled={values.isGenerating}
                        type="submit"
                        className="btn-4 btn-sm btn-outline-secondary px-5 pt-2 w-100"
                      >
                        <p className="text-center">
                          {values.id ? "Actualizar" : "Guardar"}
                        </p>
                      </button>
                    </div>
                    <div className="col-12 col-lg-4">
                      <button
                        disabled={values.isGenerating}
                        type="button"
                        onClick={values.handleReset}
                        className="btn-3 btn-sm btn-outline-secondary px-5 pt-2 w-100"
                      >
                        <p className="text-center">
                          {values.id ? "Restablecer" : "Borrar"}
                        </p>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateText;
