import React from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { truncateText } from "../../utils/Truncate";

const Texts = ({ currentItems, itemOffset, handleDelete }) => {
  if (currentItems.length <= 0) {
    return (
      <tr className="text-center">
        <td colSpan={6}>No hay textos.</td>
      </tr>
    );
  }
  return currentItems.map((text, index) => {
    return (
      <tr key={text.id}>
        <td>{index + 1 + itemOffset}</td>
        <td>{truncateText(text.text, 50)}</td>
        <td>{text.numberOfQuestions}</td>
        <td>
          <div className="d-flex justify-content-center">
            <Link
              className="text-link-1 mx-1"
              to={`/actions/texts/edit/${text.id}`}
            >
              <AiOutlineEdit size={20} />
            </Link>
            <Link
              className="text-link-1 mx-1"
              to={"#"}
              onClick={() => handleDelete(text.id)}
            >
              <AiOutlineDelete size={20} />
            </Link>
          </div>
        </td>
      </tr>
    );
  });
};

export default Texts;
