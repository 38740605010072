import { useLocation } from "react-router-dom";
import charly from "../assets/images/charly.svg";
import charly2 from "../assets/images/charly2.svg";
import wave from "../assets/images/wave.svg";

const BodyAlt = ({ children }) => {
  const { pathname } = useLocation();
  const img = pathname === "/" ? charly2 : charly;

  return (
    <>
      <div className="container">
        <img className="wave" src={wave} alt="" />

        <div className="wave2">{children}</div>
      </div>
    </>
  );
};

export default BodyAlt;
