import React from 'react';
import './styles/Loading.css';

const Loading = ({loggin=null, isModule=null}) => {
	if (loggin){
		return <><div className="container-center">
					<div className="loader">
					</div>
					<br />
					{isModule === 'sent' && <div className='text-muted text-center ms-3'>Esta acción puede tardarse, dependiendo del flujo de datos.</div> }
				</div> 
				</>

	}else{
		return <div className="container-center">
					<div className="loader">
					</div>
				</div> 

	}
}

export default Loading