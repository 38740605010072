import jsPDF from "jspdf";

export const generatePDF = (qaPairs, withAnswers = true) => {
  const doc = new jsPDF();

  // Definir los márgenes (en milímetros)
  const marginLeft = 20;
  const marginTop = 20;
  const marginRight = 20;
  const marginBottom = 20;

  /* const logoUrl = "/favicon.png"; // URL del logo
  console.log(logoUrl);
  // Definir dimensiones y posición del logo
  const logoWidth = 30; // Ajusta el tamaño del logo según necesites
  const logoHeight = 30;
  const logoX = marginLeft; // Posición X del logo (ajustada con margen)
  const logoY = marginTop; // Posición Y del logo (ajustada con margen)

  // Agregar el logo al PDF
  doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight); */

  // Configuración inicial para la posición Y
  let yOffset = marginTop; // Comenzamos en la posición del margen superior

  // Definir el ancho del contenido dentro de los márgenes
  const contentWidth =
    doc.internal.pageSize.getWidth() - marginLeft - marginRight;
  const pageHeight = doc.internal.pageSize.getHeight();

  // Título del PDF
  doc.setFontSize(16);
  if (withAnswers) {
    doc.text("Preguntas y Respuestas", marginLeft, yOffset);
  } else {
    doc.text("Preguntas", marginLeft, yOffset);
  }
  yOffset += 20; // Espacio después del título

  // Iterar sobre cada par de preguntas y respuestas
  qaPairs.forEach((qa, index) => {
    const questionHeight = 10; // Altura aproximada de una pregunta
    const optionHeight = 10; // Altura aproximada de cada opción
    const answerHeight = 20; // Altura aproximada de la respuesta
    const spaceAfterAswer = 10; // Espacio después de la respuesta

    // Verificar si hay espacio suficiente para la pregunta, si no, añadir una nueva página
    if (yOffset + questionHeight > pageHeight - marginBottom) {
      doc.addPage();
      yOffset = marginTop; // Reiniciar el yOffset para la nueva página
    }

    if (qa.level) {
      doc.setFontSize(14);
      doc.setTextColor(0, 128, 255);
      doc.text(`${qa.level.toUpperCase()}`, marginLeft, yOffset, {
        maxWidth: contentWidth,
      });
      yOffset += questionHeight + 2;
    }
    // Pregunta
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(`${index + 1}. ${qa.question}`, marginLeft, yOffset, {
      maxWidth: contentWidth,
    });
    yOffset += questionHeight + 10; // Espacio después de la pregunta

    // Opciones
    qa.options.forEach((option) => {
      // Verificar si hay espacio suficiente para la opción, si no, añadir una nueva página
      if (yOffset + optionHeight > pageHeight - marginBottom) {
        doc.addPage();
        yOffset = marginTop; // Reiniciar el yOffset para la nueva página
      }

      doc.setFontSize(12);
      doc.text(`- ${option}`, marginLeft + 10, yOffset, {
        maxWidth: contentWidth - 10,
      });
      yOffset += optionHeight; // Espacio entre opciones
    });

    // Verificar si hay espacio suficiente para la respuesta, si no, añadir una nueva página
    if (yOffset + answerHeight > pageHeight - marginBottom) {
      doc.addPage();
      yOffset = marginTop; // Reiniciar el yOffset para la nueva página
    }
    if (withAnswers) {
      // Respuesta
      doc.setFontSize(14);
      doc.setTextColor(0, 128, 0); // Color verde para la respuesta correcta
      doc.text(
        `Respuesta: ${qa.answer}`,
        marginLeft,
        yOffset + spaceAfterAswer,
        {
          maxWidth: contentWidth,
        }
      );
      yOffset += answerHeight + spaceAfterAswer;
    } else {
      yOffset += answerHeight;
    }
    // Resetear color a negro para la próxima pregunta
    doc.setTextColor(0, 0, 0);
  });

  // Guardar el PDF
  if (withAnswers) {
    doc.save("Preguntas_Respuestas.pdf");
  } else {
    doc.save("Preguntas.pdf");
  }
};
