export const error1 = {
    code: 1,
    message: "El código es inválido, por favor verifique y vuelva a intentar"
}

export const error2 = {
    code: 2,
    message: "Este código ya esta en uso, por favor verifique y vuelva a intentar",
}

export const error3 = {
    code: "auth/email-already-in-use",
    message: "Este correo ya está en uso",
}

export const error4 = {
    code: "auth/user-not-found",
    message: "Este usuario no se encuentra registrado",
}

export const error5 = {
    code: "auth/invalid-email",
    message: "Correo inválido",
}

export const error6 = {
    code: "auth/too-many-requests",
    message: "Por favor intente de nuevo en unos minutos",
}

export const error7 = {
    code: "auth/missing-email",
    message: "El campo correo no puede estar vacio",
}






