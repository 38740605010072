import React, { useState } from "react";
import Header from "../../components/protected/Header";
import ReactPaginate from "react-paginate";
import Footer from "../../components/footer";
import Texts from "../../components/protected/texts/Texts";
import { Link } from "react-router-dom";

const ListText = ({ values }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  console.log(values.data);

  const currentItems = values.data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">{values.title}</h1>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2 mb-3 border-bottom">
              <h4>Textos</h4>
              <Link
                className="text-center btn-4 btn-sm btn-outline-secondary w-lg-10 w-25"
                to={"/actions/create-text"}
              >
                + Crear
              </Link>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-sm text-center">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Texto</th>
                    <th scope="col">Número de preguntas</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems && (
                    <Texts
                      currentItems={currentItems}
                      itemOffset={indexOfFirstItem}
                      handleDelete={values.handleDelete}
                    />
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <ReactPaginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(values.data.length / itemsPerPage)}
                  previousLabel={"Anterior"}
                  nextLabel={"Siguiente"}
                  containerClassName={"pagination"}
                  className={"pagination "}
                  pageLinkClassName={"page-link"}
                  previousLinkClassName={"page-link previous"}
                  nextLinkClassName={"page-link next"}
                  activeLinkClassName={"active"}
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer logged={values.logged} />
    </>
  );
};

export default ListText;
