import useFetchTexts from "../../hooks/useFetchTexts";
import Loading from "../../components/Loading";
import ListText from "./ListText";
import Swal from "sweetalert2";
import { remove, ref } from "firebase/database";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { database } from "../../config/firebase";

const ListTextContainer = () => {
  const { data, loading, error } = useFetchTexts();
  const { user } = UserAuth();
  const navigate = useNavigate();

  let title = "Textos de lectura creados";

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Deseas eliminar el registro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#cc3333",
      cancelButtonColor: "#dee2e6",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const textRef = ref(database, `users/${user.uid}/texts/${id}`);
        await remove(textRef);
        Toast.fire({
          icon: "success",
          title: "Eliminado correctamente.",
        }).then(() => {
          navigate("/actions/list-texts");
        });
      }
    });
  };

  const values = {
    data: data,
    title,
    handleDelete,
  };

  if (loading) return <Loading />;

  return <ListText values={values} />;
};

export default ListTextContainer;
