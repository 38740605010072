import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Input from '../components/form/input';
import { UserAuth } from '../context/AuthContext';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { useState } from 'react';

const ForgotPassword = () => {
	
	const [required, setRequired] = useState("");
	const { loading, error, passwordRecovery, user } = UserAuth();
	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: ''
		}
	});
	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 5000,
		timerProgressBar: true,
		didOpen: (toast) => {
		  toast.addEventListener('mouseenter', Swal.stopTimer)
		  toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	  })
	
	const navigate = useNavigate();

	const onSubmit = async (data,e) => {
		e.preventDefault();
		if(!data.email){
			setRequired("El campo correo es requerido");
			return;
		}
		await passwordRecovery(data.email);
		if(!error)
			Toast.fire({
				icon: 'success',
				title: "Correo enviado con éxito",
				text: "Revisa tu bandeja de entrada y sigue las instrucciones"
			}).then(() => {
				navigate("/");
			});
	};

	if (error){
		 Toast.fire({
			icon: 'error',
			title: error,
		  }).then(() => {
			return;
		  });
	}

	if(loading)
		return <Loading/>
	
	return (
		<div className="login-content">
			<form onSubmit={handleSubmit(onSubmit)}>
				<img src="/assets/img/logo-02.svg" alt="" />
				<h3 className="title">Reestablecer contraseña</h3>
				<div id="input-div-forgot" className="input-div one">
					<div className="i">
						<i className="fas fa-user"></i>
					</div>
					<Input
						// label=""
						control={control}
						name="email"
						inputId="input-div-forgot"
						type="email"
						id="email"
						label="Correo electrónico"
					/>
				</div>
				<div className='text-danger mt-0'>{required ? required : ''}</div>
				<input type="submit" className="btn" value="Enviar instrucciones" />
				<Link to="/">
					Ya tienes una cuenta? <strong>Inicia Sesión.</strong>
				</Link>
			</form>
		</div>
	);
};

export default ForgotPassword;
