import React, {useState, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { useNavigate } from 'react-router-dom';
import Loading from "../Loading";
import { UserAuth } from "../../context/AuthContext";
import { RxExit } from 'react-icons/rx';
import { AiOutlineMenu } from 'react-icons/ai';

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const [width, setWidth] = useState(null);
    const [show, setShow] = useState("");
    const [close, setClose] = useState("");
    const { logout, loading } = UserAuth();
    const navigate = useNavigate();

    const handleLogout = useCallback(async () => {
        try {
            await logout();
            navigate('/');
            /* console.log('You are logged out') */
        } catch (e) {
            /* console.log(e.message); */
        }
    }, [logout, navigate]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        /* console.log(width); */
        if(width<768){ 
            setShow("");
            setClose(<a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 text-link-2" href="#" onClick={handleLogout}><RxExit size={20}/></a>);
        }else{
            setClose(<a className="btn-4 btn-sm btn-outline-secondary px-3 pt-2" href="#" onClick={handleLogout}>Cerrar sesión</a>);
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    },[width, toggle])

    useEffect(() => {
        if (toggle) {
            setShow("show");
        }else{
            setShow("");
        }
      
    }, [toggle])
    

    if(loading)
        return <Loading/>
        
    return (<>
                <header className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow">
                    <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3 text-link-2" to="/dashboard">MIABCONLINE</Link>
                    {/* <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">   
                    </button> */}
                    <button onClick={() => {setToggle(!toggle)}} className="navbar-toggler d-md-none collapsed d-flex justify-content-center">
                        <span className="text-white"><AiOutlineMenu size={20} className="mx-1 my-1"/></span>
                    </button>
                    {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" /> */}
                    <div className="navbar-nav">
                        <div className="nav-item text-nowrap me-3">
                           {/*  <a className="btn-4 btn-sm btn-outline-secondary px-3 pt-2" href="#" onClick={handleLogout}>{close}</a> */}
                           {close}
                        </div>
                    </div>
                </header>
                <Menu show={show} toggle={toggle}/>
            </>
    )

}

export default Header