import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/protected/Header";
import { lastTime } from "../../components/utils/Time";

const Dashboard = ({ values }) => {
  const [lastTimeConn, setLastTimeConn] = useState();
  const [timeConn, setTimeConn] = useState();
  const [numConne, setNumConne] = useState();

  useEffect(() => {
    if (!lastTime(values.learner)) {
      return;
    }
    const { date, diff, numConn } = lastTime(values.learner);
    setLastTimeConn(date);
    setNumConne(numConn);
    setTimeConn(diff);
  }, [values.learner]);

  const footerBg = () => {
    if (timeConn > 168) {
      return "alert-danger";
    }
    if (timeConn > 24 && timeConn <= 168) {
      return "alert-warning";
    }
    if (timeConn <= 24) {
      return "alert-success";
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Dashboard</h1>
            </div>

            {/* <canvas className="my-4 w-100" id="myChart" width="900" height="380"></canvas> */}

            {/*  <h2>Section title</h2> */}
            <div className="row mt-3">
              {/*  <h3 className="text-center">Información de general</h3> */}

              <div className="d-block mt-2">
                <div className="card text-center shadow">
                  <div className="card-header">
                    Información general de {values.learner.learnerName}
                  </div>

                  <img
                    src={values.learner.profileImageLink}
                    className="rounded m-auto d-block my-3"
                    alt="Image"
                    height={180}
                    width={150}
                  />
                  <div className="card-body">
                    <p>
                      <strong>Correo </strong> : {values.learner.email}
                    </p>
                    <p className="mb-4">
                      <strong>Nombre de usuario </strong> :{" "}
                      {values.learner.username}
                    </p>
                    <p className="mb-4">
                      <strong>Grado </strong> :{" "}
                      {values.learner.estudiandoGrado.charAt(0).toUpperCase() +
                        values.learner.estudiandoGrado.slice(1)}
                    </p>
                    <hr />
                    <p className="mt-4">
                      <strong>Nombre del aprendíz </strong>
                    </p>
                    <audio
                      src={values.learner.apprenticeNameLink}
                      controls
                      type="audio/mp4"
                    />
                    <p>
                      <strong>Frase de felicitación </strong>
                    </p>
                    <audio
                      src={values.learner.congratulatoryPhraseLink}
                      controls
                      type="audio/mp4"
                    />
                    {/* <hr />
                    <p>
                      <strong>Número de ingresos a la app móvil </strong>
                    </p>
                    <h2 className="display-6">{numConne}</h2> */}
                  </div>
                  {/* <div className={"card-footer " + footerBg()}>
                    {timeConn > 168 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                        viewBox="0 0 16 16"
                        role="img"
                        aria-label="Danger:"
                      >
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                    )}
                    {timeConn > 24 && timeConn <= 168 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                        viewBox="0 0 16 16"
                        role="img"
                        aria-label="Warning:"
                      >
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                    )}
                    {timeConn <= 24 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-check-circle-fill flex-shrink-0 me-2"
                        viewBox="0 0 16 16"
                        role="img"
                        aria-label="Success:"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    )}
                    Último inicio de sesión {lastTimeConn}
                    <div>
                      <i>
                        Se actualizará únicamente al cerrar sesión en la app
                        móvil
                      </i>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* 
                                <div className="col"></div>
                                <div className="col"></div> */}
            </div>
          </main>
        </div>
      </div>
      <Footer logged={values.logged} />
    </>
  );
};

export default Dashboard;
