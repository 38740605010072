import React, { useState } from 'react';
import Loading from '../../components/Loading';
import Dashboard from './Dashboard';
import useFetch from '../../hooks/useFetch';

const DashboardContainer = () => {
  /* const { user } = UserAuth(); */
  const { data, loading, error } = useFetch();

  const values = {
    logged : true,
    learner : data
} 
  if (loading)
    return <Loading/> 

  return <Dashboard values={values}/>
  
  /* (
    <div className='max-w-[600px] mx-auto my-16 p-4'>
      <h1 className='text-2xl font-bold py-4'>Account</h1>
      <p>User Email: {user && user.email}</p>

      <button onClick={handleLogout} className='border px-6 py-2 my-4'>
        Logout
      </button>
    </div>
  ); */
};

export default DashboardContainer;