import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Loading from "./Loading";
import { useEffect } from "react";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { user, loading } = UserAuth();
  useEffect(() => {
    if (!user && user !== undefined) {
      navigate("/");
    }
  }, [user]);
  /* console.log("user", user); */
  if (loading) {
    return <Loading />; // or loading spinner, etc
  }
  if (user === undefined) {
    return <Loading />; // or loading spinner, etc
  }
  /* if (!user) {
    return navigate('/'); 
  } */
  return children;
};

export default ProtectedRoute;
