import { createBrowserRouter, Navigate } from "react-router-dom";
import Body from "../components/body";
import Nav from "../components/nav";
import Footer from "../components/footer";
import LoginPage from "./login";
import ForgotPasswordPage from "./forgot-password";
import RegisterPage from "./register";
import "../index.css";
import Dashboard from "./protected/DashboardContainer";
import ProtectedRoute from "../components/ProtectedRoute";
import WordsCreatedContainer from "./protected/WordsCreatedContainer";
import WordsSentContainer from "./protected/WordsSentContainer";
import CreateTextContainer from "./protected/CreateTextContainer";
import ListTextContainer from "./protected/ListTextContainer";
import Terms from "./terms";
import BodyAlt from "../components/body-alt";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/",
    element: (
      <>
        <Nav />
        <Body>
          <LoginPage />
        </Body>
        <Footer />
      </>
    ),
  },
  {
    path: "/register/:step",
    element: (
      <>
        <Nav />
        <Body>
          <RegisterPage />
        </Body>
        <Footer />
      </>
    ),
  },
  {
    path: "/privacy",
    element: (
      <>
        <Nav />
        <BodyAlt>
          <Terms />
          <Footer isTerms={true} />
        </BodyAlt>
      </>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <>
        <Nav />
        <Body>
          <ForgotPasswordPage />
        </Body>
        <Footer />
      </>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </>
    ),
  },
  {
    path: "/words/created",
    element: (
      <>
        <ProtectedRoute>
          <WordsCreatedContainer />
        </ProtectedRoute>
      </>
    ),
  },
  {
    path: "/words/received",
    element: (
      <>
        <ProtectedRoute>
          <WordsCreatedContainer isModule={"received"} />
        </ProtectedRoute>
      </>
    ),
  },
  {
    path: "/words/sent",
    element: (
      <>
        <ProtectedRoute>
          <WordsSentContainer isModule={"sent"} />
        </ProtectedRoute>
      </>
    ),
  },
  /* {
		path: '/words/edited',
		element: (
			<>
				<ProtectedRoute>
					<WordsCreatedContainer isEdited={true}/>
				</ProtectedRoute>
			</>
		)
	}, */
  {
    path: "/family/created",
    element: (
      <>
        <ProtectedRoute>
          <WordsCreatedContainer isModule={"family"} />
        </ProtectedRoute>
      </>
    ),
  },
  {
    path: "/actions/create-text",
    element: (
      <>
        <ProtectedRoute>
          <CreateTextContainer />
        </ProtectedRoute>
      </>
    ),
  },
  ,
  /* {
    path: "/actions/testing-pdf",
    element: (
      <>
        <ProtectedRoute>
          <TestingPdfContainer />
        </ProtectedRoute>
      </>
    ),
  }, */
  {
    path: "/actions/list-texts",
    element: (
      <>
        <ProtectedRoute>
          <ListTextContainer />
        </ProtectedRoute>
      </>
    ),
  },
  {
    path: "/actions/texts/edit/:id",
    element: (
      <>
        <ProtectedRoute>
          <CreateTextContainer isEdit={true} />
        </ProtectedRoute>
      </>
    ),
  },
]);
